import Vue from 'vue'
import {Query} from '../../api'
import {default_return_data_obj, default_return_data_array} from './helpers/state_helper'
import {current_user} from "./state/user";
import {
  RESET_QUERY_DATA, SET_QUERY_DATA, MERGE_PATIENT_DATA
} from './types/query_types';

// Initialisations below are were really just there to ensure reactivity of the Vue state.
// This is not strictly required now and will work without it however having a template for how
// the data will be structured does allow us to just assume that certain fields will be in an
// object without NULL checking everything.
//
const init_functions = {
  
  
  
  
  usage_overview: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_admin_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_admin_counts_over_time: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_ward: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_ward_drugs: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_ward_drugs_table: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_prediction: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  usage_prediction_breakdown_locations: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_admins_list: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_patient_admins: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  usage_admins_summary: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  antibiotics_active_orders: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_dot: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_ddd: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_order_length: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  antibiotics_order_length_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  antibiotics_order_specialty: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_admin_specialty: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_route: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  antibiotics_shortage: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  prescribing_active_prescriptions: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  prescribing_opioids: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  omitted_doses_league_table: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  omitted_doses_drug_reason: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  omitted_doses_administrator_reason: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  omitted_doses_early_late_doses_league_table: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  omitted_doses_drug_time_sensitivity: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  omitted_doses_early_late_trends: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  omitted_doses_hourly_time_sensitivity: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  omitted_doses_early_late_trends: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  omitted_doses_overview_standard: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  omitted_doses_overview_hospital: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  omitted_doses_ward_view: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  omitted_doses_admin_reasons: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  omitted_doses_admin_reasons_ward_compare: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  anticholinergics_cognition: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  anticholinergics_overview: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  anticholinergics_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  parkinsons_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  parkinsons_delayed_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  parkinsons_delayed_timeline: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  parkinsons_early_late_doses_league_table: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  parkinsons_drug_time_sensitivity: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  parkinsons_early_late_trends: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  parkinsons_hourly_time_sensitivity: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  parkinsons_early_late_trends: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  admin_burden_active_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  admin_burden_active_patients: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  admin_burden_overview: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  admin_burden_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  admin_burden_scores: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  hospital_admissions: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  hospital_bed_days: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  stock_history: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  stock_shortage_summary: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  stock_shortages: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  stock_lookup: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  safety_active_overview: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  safety_location_overview: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  safety_ward_view: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  safety_covert_medications: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  patient_omitted_drug_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  vte_active_patient_indicators: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  patient_omitted_drug_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  performance_ward: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  performance_ward_checks: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  performance_ward_check_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  opioids_admins_league_table: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_vte_risk: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_active_vte_risk_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_vte_risk_history: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_vte_risk_history_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_allergy_verifications: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_allergy_verifications_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_allergy_verifications_history: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_allergy_verifications_history_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_oxygen: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_active_oxygen_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_oxygen_history: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  monitoring_oxygen_history_patient: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  monitoring_patient_usage_stats: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  probe_active_users: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  probe_dashboard_popularity: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  pharmacy_monitoring_summary: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  patient_omitted_drug_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  patient_unvalidated_drug_breakdown: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  
  controlled_ward_history: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
  
  
  
  insulins_delayed_counts: (value = null) => JSON.parse(default_return_data_obj(value)),
  
  
  
  
  
  
};

const initial_drug_data_state = {
  
  
  
  
  usage_overview: init_functions.usage_overview(),
  
  
  
  
  
  
  
  usage_admin_counts: init_functions.usage_admin_counts(),
  
  
  
  
  
  
  
  usage_admin_counts_over_time: init_functions.usage_admin_counts_over_time(),
  
  
  
  
  
  
  
  usage_ward: init_functions.usage_ward(),
  
  
  
  
  
  
  
  usage_ward_drugs: init_functions.usage_ward_drugs(),
  
  
  
  
  
  
  
  usage_ward_drugs_table: init_functions.usage_ward_drugs_table(),
  
  
  
  
  
  
  
  usage_prediction: init_functions.usage_prediction(),
  
  
  
  
  usage_prediction_breakdown_locations: init_functions.usage_prediction_breakdown_locations(),
  
  
  
  
  
  
  
  usage_admins_list: init_functions.usage_admins_list(),
  
  
  
  
  
  
  
  usage_patient_admins: init_functions.usage_patient_admins(),
  
  
  
  
  
  
  
  usage_admins_summary: init_functions.usage_admins_summary(),
  
  
  
  
  
  
  
  
  
  antibiotics_active_orders: init_functions.antibiotics_active_orders(),
  
  
  
  
  
  
  
  antibiotics_dot: init_functions.antibiotics_dot(),
  
  
  
  
  
  
  
  antibiotics_ddd: init_functions.antibiotics_ddd(),
  
  
  
  
  
  
  
  antibiotics_order_length: init_functions.antibiotics_order_length(),
  
  
  
  antibiotics_order_length_breakdown: init_functions.antibiotics_order_length_breakdown(),
  
  
  
  
  
  
  
  
  antibiotics_order_specialty: init_functions.antibiotics_order_specialty(),
  
  
  
  
  
  
  
  antibiotics_admin_specialty: init_functions.antibiotics_admin_specialty(),
  
  
  
  
  
  
  
  antibiotics_route: init_functions.antibiotics_route(),
  
  
  
  
  
  
  
  antibiotics_shortage: init_functions.antibiotics_shortage(),
  
  
  
  
  
  
  
  
  
  prescribing_active_prescriptions: init_functions.prescribing_active_prescriptions(),
  
  
  
  
  
  
  
  prescribing_opioids: init_functions.prescribing_opioids(),
  
  
  
  
  
  
  
  
  
  omitted_doses_league_table: init_functions.omitted_doses_league_table(),
  
  
  
  omitted_doses_drug_reason: init_functions.omitted_doses_drug_reason(),
  
  
  
  
  omitted_doses_administrator_reason: init_functions.omitted_doses_administrator_reason(),
  
  
  
  
  
  
  
  
  omitted_doses_early_late_doses_league_table: init_functions.omitted_doses_early_late_doses_league_table(),
  
  
  
  omitted_doses_drug_time_sensitivity: init_functions.omitted_doses_drug_time_sensitivity(),
  
  
  
  
  omitted_doses_early_late_trends: init_functions.omitted_doses_early_late_trends(),
  
  
  
  
  omitted_doses_hourly_time_sensitivity: init_functions.omitted_doses_hourly_time_sensitivity(),
  
  
  
  
  
  
  
  
  omitted_doses_early_late_trends: init_functions.omitted_doses_early_late_trends(),
  
  
  
  
  
  
  
  omitted_doses_overview_standard: init_functions.omitted_doses_overview_standard(),
  
  
  
  
  omitted_doses_overview_hospital: init_functions.omitted_doses_overview_hospital(),
  
  
  
  
  
  
  
  omitted_doses_ward_view: init_functions.omitted_doses_ward_view(),
  
  
  
  
  
  
  
  omitted_doses_admin_reasons: init_functions.omitted_doses_admin_reasons(),
  
  
  
  
  
  
  
  omitted_doses_admin_reasons_ward_compare: init_functions.omitted_doses_admin_reasons_ward_compare(),
  
  
  
  
  
  
  
  
  
  anticholinergics_cognition: init_functions.anticholinergics_cognition(),
  
  
  
  
  
  
  
  anticholinergics_overview: init_functions.anticholinergics_overview(),
  
  
  
  
  
  
  
  anticholinergics_breakdown: init_functions.anticholinergics_breakdown(),
  
  
  
  
  
  
  
  
  
  parkinsons_counts: init_functions.parkinsons_counts(),
  
  
  
  
  
  
  
  parkinsons_delayed_counts: init_functions.parkinsons_delayed_counts(),
  
  
  
  
  
  
  
  parkinsons_delayed_timeline: init_functions.parkinsons_delayed_timeline(),
  
  
  
  
  
  
  
  parkinsons_early_late_doses_league_table: init_functions.parkinsons_early_late_doses_league_table(),
  
  
  
  parkinsons_drug_time_sensitivity: init_functions.parkinsons_drug_time_sensitivity(),
  
  
  
  
  parkinsons_early_late_trends: init_functions.parkinsons_early_late_trends(),
  
  
  
  
  parkinsons_hourly_time_sensitivity: init_functions.parkinsons_hourly_time_sensitivity(),
  
  
  
  
  
  
  
  
  parkinsons_early_late_trends: init_functions.parkinsons_early_late_trends(),
  
  
  
  
  
  
  
  
  
  admin_burden_active_counts: init_functions.admin_burden_active_counts(),
  
  
  
  
  admin_burden_active_patients: init_functions.admin_burden_active_patients(),
  
  
  
  
  
  
  
  admin_burden_overview: init_functions.admin_burden_overview(),
  
  
  
  
  
  
  
  admin_burden_counts: init_functions.admin_burden_counts(),
  
  
  
  
  
  
  
  admin_burden_scores: init_functions.admin_burden_scores(),
  
  
  
  
  
  
  
  
  
  hospital_admissions: init_functions.hospital_admissions(),
  
  
  
  
  
  
  
  hospital_bed_days: init_functions.hospital_bed_days(),
  
  
  
  
  
  
  
  
  
  stock_history: init_functions.stock_history(),
  
  
  
  
  
  
  
  stock_shortage_summary: init_functions.stock_shortage_summary(),
  
  
  
  
  
  
  
  stock_shortages: init_functions.stock_shortages(),
  
  
  
  
  
  
  
  stock_lookup: init_functions.stock_lookup(),
  
  
  
  
  
  
  
  
  
  safety_active_overview: init_functions.safety_active_overview(),
  
  
  
  
  safety_location_overview: init_functions.safety_location_overview(),
  
  
  
  
  
  
  
  safety_ward_view: init_functions.safety_ward_view(),
  
  
  
  
  
  
  
  safety_covert_medications: init_functions.safety_covert_medications(),
  
  
  
  patient_omitted_drug_breakdown: init_functions.patient_omitted_drug_breakdown(),
  
  
  
  
  
  
  
  
  
  
  vte_active_patient_indicators: init_functions.vte_active_patient_indicators(),
  
  
  
  patient_omitted_drug_breakdown: init_functions.patient_omitted_drug_breakdown(),
  
  
  
  
  
  
  
  
  
  
  performance_ward: init_functions.performance_ward(),
  
  
  
  
  
  
  
  performance_ward_checks: init_functions.performance_ward_checks(),
  
  
  
  performance_ward_check_breakdown: init_functions.performance_ward_check_breakdown(),
  
  
  
  
  
  
  
  
  
  
  opioids_admins_league_table: init_functions.opioids_admins_league_table(),
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_vte_risk: init_functions.monitoring_active_vte_risk(),
  
  
  
  
  monitoring_active_vte_risk_patient: init_functions.monitoring_active_vte_risk_patient(),
  
  
  
  
  monitoring_vte_risk_history: init_functions.monitoring_vte_risk_history(),
  
  
  
  
  monitoring_vte_risk_history_patient: init_functions.monitoring_vte_risk_history_patient(),
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_allergy_verifications: init_functions.monitoring_allergy_verifications(),
  
  
  
  
  monitoring_allergy_verifications_patient: init_functions.monitoring_allergy_verifications_patient(),
  
  
  
  
  monitoring_allergy_verifications_history: init_functions.monitoring_allergy_verifications_history(),
  
  
  
  
  monitoring_allergy_verifications_history_patient: init_functions.monitoring_allergy_verifications_history_patient(),
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_oxygen: init_functions.monitoring_active_oxygen(),
  
  
  
  
  monitoring_active_oxygen_patient: init_functions.monitoring_active_oxygen_patient(),
  
  
  
  
  monitoring_oxygen_history: init_functions.monitoring_oxygen_history(),
  
  
  
  
  monitoring_oxygen_history_patient: init_functions.monitoring_oxygen_history_patient(),
  
  
  
  
  
  
  monitoring_patient_usage_stats: init_functions.monitoring_patient_usage_stats(),
  
  
  
  
  
  
  
  
  
  probe_active_users: init_functions.probe_active_users(),
  
  
  
  
  
  
  
  probe_dashboard_popularity: init_functions.probe_dashboard_popularity(),
  
  
  
  
  
  
  
  
  
  pharmacy_monitoring_summary: init_functions.pharmacy_monitoring_summary(),
  
  
  
  patient_omitted_drug_breakdown: init_functions.patient_omitted_drug_breakdown(),
  
  
  
  
  patient_unvalidated_drug_breakdown: init_functions.patient_unvalidated_drug_breakdown(),
  
  
  
  
  
  
  
  
  
  
  controlled_ward_history: init_functions.controlled_ward_history(),
  
  
  
  
  
  
  
  
  
  insulins_delayed_counts: init_functions.insulins_delayed_counts(),
  
  
  
  
  
  
};


function processQueryData(commit, dispatch, query, dashboard, data) {
  commit(SET_QUERY_DATA, {query: query, dashboard: dashboard, data: data});
  if (data.filters) {
    dispatch('setFilters', {dashboard: dashboard, filters: data.filters});
    dispatch('updateLastFilterHistory', {dashboard: dashboard, filters: data.filters});
  }

  const cache = _.get(data, ['meta', 'cache']);
  if (cache) {
    dispatch('addToCache', cache)
  }
}

const state = {
  drug_data: $.extend(true, {}, initial_drug_data_state)
};

const actions = {
  resetQueryData({commit}, data) {
    commit(RESET_QUERY_DATA, data)
  },

  async queryDataReceived({commit, dispatch}, data) {
    processQueryData(commit, dispatch, data.query, data.dashboard, data.data);
  },

  async runQuery({commit, dispatch}, {query, dashboard, dashboard_info}) {
    const response = await Query.query(query, dashboard, dashboard_info);
    if (!(response.data.meta && response.data.meta.history_id)) {
      return
    }

    processQueryData(commit, dispatch, query, dashboard, response.data);
    if (!current_user.ui[dashboard].fetch_patient_data) {
      return
    }

    const patientIds = response.data.data.map(a => a.mapped_patient_id);
    dispatch('runHospitalQuery', {query: query, dashboard: dashboard, data: patientIds});
  },

  async setQueryData({commit, dispatch}, {query, dashboard, data}) {
    processQueryData(commit, dispatch, query, dashboard, data);
    if (!current_user.ui[dashboard].fetch_patient_data) {
      return
    }

    const patientIds = response.data.data.map(a => a.mapped_patient_id);
    dispatch('runHospitalQuery', {query: query, dashboard: dashboard, data: patientIds});
  },

  async runHospitalQuery({commit, dispatch}, {query, data, dashboard}) {
    const hospResponse = await Query.hospitalQuery(current_user.hospital_name, data, current_user.api_token);
    if (hospResponse.data) {
      commit(MERGE_PATIENT_DATA, {query: query, dashboard: dashboard, data: hospResponse.data});
    }
  },


  async runHospitalQueryForData({commit, dispatch}, {query, data, dashboard}) {
    const hospResponse = await Query.hospitalQuery(current_user.hospital_name, data, current_user.api_token);
    if (hospResponse.data) {
      return hospResponse.data;
    }
    return null;
  },

  async exportChart({commit}, {historyId, format}) {
    const response = await Query.exportChart(historyId, format);
    return response.data;
  }
};

const mutations = {
  [SET_QUERY_DATA](state, {query, data}) {
    Vue.set(state.drug_data, query, Object.freeze(data))
  },
  [MERGE_PATIENT_DATA](state, {query, data}) {
    let preMergeData = _.cloneDeep(state.drug_data[query]);

    for (let i = 0; i < preMergeData.data.length; i++) {
      // Get the mapped patient id from this and find it in the hosp response
      for (let x = 0; x < data.length; x++) {
        if (data[x].mapped_patient_id === preMergeData.data[i].mapped_patient_id) {
          // Update the name of the patient andbreak out the loop
          preMergeData.data[i].patient_name = data[x].patient_name;
          preMergeData.data[i].hospital_number = data[x].hospital_number;
          preMergeData.data[i].nhs_number = data[x].nhs_number;
          break;
        }
      }
    }

    Vue.set(state.drug_data, query, Object.freeze(preMergeData))
  },
  [RESET_QUERY_DATA](state, data) {
    if (!data) {
      Vue.set(state, 'drug_data', $.extend(true, {}, initial_drug_data_state));
      return;
    }
    _.each(data.queries, (query) => {
      const obj = Object.freeze(init_functions[query](null))
      Vue.set(state.drug_data, query, obj)
    });
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}
