import { RRule} from 'rrule'

export default {
  identity: (val) => `${val == null ? '' : val}`,
  percentage: (val) => `${(parseFloat(val) * 100).toFixed(1)}%`,
  percentTag: (val) => `${val}%`,
  titleize: (val) => val ? val.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()) : '',
  appendage: (val, args) => val && args.condition ? val.concat(args.append) : val,
  prettyDate: (val) => val ? moment(val).fromNow() : val,
  prettyStockDate: (val) => val == 'a' ? 'No issues last week.' : val == 'b' ? 'No Stock' : moment(val).fromNow(),
  prettyTime: (val) => val ? moment(val).format('HH:mm') : val,
  prettyDatetime: (val) => val ? moment(val).format('DD MMM YY - HH:mm') : val,
  datetimeToMinute: (val) => val ? moment(val).format('YYYY-MM-DD HH:mm') : val,
  yesNo: (val) => (val === 1 || val === '1') ? 'Yes' : 'No',
  toDecimalTwo: (val) => `${(parseFloat(val.toFixed(2)))}`,
  prettyDelay: (val) => {
    if(!val) {
      return 'N/A'
    }

    const hrsMins = (v) => {
      const hrs = Math.floor(v)
      if(v % 60 === 0) {
        return `${hrs} hours`
      }
      const mins = Math.floor((v % 1) * 60)
      return `${hrs} hours ${mins} mins`
    }

    if(val <= 72) {
      return hrsMins(val)
    }

    if(val > 72) {
      const days = Math.floor(val / 24)
      const hhmm = hrsMins(val % 24)
      return `${days} days ${hhmm}`
    }

    if(val < 1) {
      return `${val*60} mins`
    }
  },
  rrule: (val) => {
    if(!val) return val
    try {
      return RRule.fromString(`RRULE:${val.rrule}`).toText();
    }
    catch(err) {
      return '';
    }
  },
}