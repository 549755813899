import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export function createLocale() {
  const messages = {
    en: {
      stock_shortage_summary: 'Shortage Summary',
      stock_shortage_summary_title: 'Shortage summary for <b>{wards}</b>',
      stock_shortage_summary_default_title: 'Shortage Summary',
      parkinsons_hourly_time_sensitivity: 'Parkinsons Hourly Time Sensitivity',
      parkinsons_hourly_time_sensitivity_title: 'Hourly breakdown of Parkinsons drugs <b>{adminDelta}</b> for dates <b>{dateRange}</b>',
      stock_lookup: 'Stock Lookup',
      stock_lookup_title: 'Stock Lookup',
      stock_shortages: 'Stock Shortages',
      stock_shortages_title: 'Stock Shortages for <b>{wards}</b>',
      stock_shortages_default_title: 'Stock Shortages',
      usage_admins_summary: 'Administered Dose Summary',
      usage_admins_summary_title: 'Administration Dose Summary of <b>{drugInfo}</b> on <b>{wards}</b> for dates <b>{dateRange}</b>',
      omitted_doses_hourly_time_sensitivity: 'Omitted Doses Hourly Time Sensitivity',
      omitted_doses_hourly_time_sensitivity_title: 'Hourly breakdown of drugs <b>{adminDelta}</b> for dates <b>{dateRange}</b> for <b>{routeCodes}</b> routes',
      performance_ward_check_breakdown: 'Performance Ward Check Breakdown',
      performance_ward_check_breakdown_title: 'Checks breakdown on ward <b>{wards}</b> for dates <b>{dateRange}</b>',
      performance_ward_checks: 'Ward Fridge/Cupboard Checks',
      performance_ward_checks_title: 'Ward check pass rates for <b>{wards}</b> on dates <b>{dateRange}</b>',
      usage_patient_admins: 'Administered to Patient',
      usage_patient_admins_title: 'Administered <b>{drugs}</b> to patient <b>{patientId}</b> for dates <b>{dateRange}</b>',
      usage_admins_list: 'Administered on Ward',
      usage_admins_list_title: 'Administrations of <b>{drugInfo}</b> on <b>{wards}</b> for dates <b>{dateRange}</b>',
      controlled_ward_history: 'Controlled Drug History Ward View',
      controlled_ward_history_title: 'Administrations of <b>{drugInfo}</b> on <b>{wards}</b> for dates <b>{dateRange}</b>',
      pharmacy_monitoring_summary: 'Monitoring Summary',
      pharmacy_monitoring_summary_title: 'Monitoring Summary for ward <b>{wardName}</b>',
      monitoring_patient_usage_stats: 'Patient Drug Stats',
      monitoring_patient_usage_stats_title: 'Patient drug stats for <b>{drugs}</b> over the last <b>{dateRangeInterval}</b> days',
      probe_dashboard_popularity: 'Dashboard Popularity',
      probe_dashboard_popularity_title: 'Dashboard popularity for dates <b>{dateRange}</b>',
      probe_active_users: 'Active Users',
      probe_active_users_title: 'Active users for dates <b>{dateRange}</b>',
      parkinsons_dot: 'Parkinsons DOT',
      parkinsons_dot_title: 'DOTs {dateGrouping} between <b>{dateRange}</b> {bedDays} for <b>{wards}</b>',
      monitoring_allergy_verifications_history_patient_title: 'Monitoring allergy verifications history patient for dates <b>{dateRange}</b>',
      monitoring_allergy_verifications_patient: 'Monitoring Allergy Verifications Patient',
      monitoring_allergy_verifications_patient_title: 'Monitoring allergy verifications patient for dates <b>{dateRange}</b>',
      monitoring_allergy_verifications_history: 'Monitoring Allergy Verifications History',
      monitoring_allergies: 'Monitoring Allergies',
      monitoring_allergies_title: 'Monitoring allergies for dates <b>{dateRange}</b>',
      monitoring_allergy_verifications: 'Monitoring Allergy Verifications',
      monitoring_vte: 'VTE',
      monitoring_active_vte_risk: 'Monitoring Active Vte Risk',
      usage_admin_counts_over_time: 'Administration Counts Time Series',
      usage_admin_counts_over_time_title: '<b>{dateGrouping}</b> admin counts for <b>{wards}</b> between <b>{dateRange}</b>',
      monitoring_daily_oxygen_history: 'Monitoring Daily Oxygen History',
      monitoring_daily_oxygen_history_title: 'Monitoring daily oxygen history for dates <b>{dateRange}</b>',
      admin_burden_active_counts: 'Today\'s Predicted Injection Levels',
      admin_burden_active_counts_title: 'Today\'s predicted {burdenCountGrouping} <b>injection</b> administration levels for <b>{wards}</b></b>',
      admin_burden_active_patients: 'Patients with administrations',
      admin_burden_active_patients_title: 'Patients with administrations',
      usage_prediction_breakdown_locations: 'Usage Prediction Breakdown Locations',
      usage_prediction_breakdown_locations_title: 'Usage prediction locations for <b>{dateRange}</b>',
      usage_prediction: 'Usage Prediction',
      usage_prediction_title: 'Usage prediction for <b>{dateRange}</b>',
      opioids_admins_league_table: 'Opioids Admins League Table',
      opioids_admins_league_table_title: 'Opioids admins league table for dates <b>{dateRange}</b>',
      opioids_admins_league_table_sub: "Data for the last <b>{dateRangeInterval} days</b> {routeCodes}",
      monitoring_oxygen_history: 'Oxygen Compliance History',
      monitoring_oxygen_history_title: 'Oxygen compliance history for admissions <b>{stayLength}</b> between <b>{dateRange}</b>',
      monitoring_oxygen_history_within_title: 'Oxygen compliance history for admissions <b>{stayLength}</b> where patients prescribed oxygen <b>{withinLength}</b> between <b>{dateRange}</b>',
      monitoring_oxygen: 'Oxygen',
      monitoring_oxygen_title: 'Monitoring oxygen for dates <b>{dateRange}</b>',
      monitoring_active_oxygen_patient: 'Active inpatients in {locationLabel} not prescribed oxygen or prescribed after {withinLengthHours}',
      monitoring_active_oxygen_patient_within_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours either not prescribed oxygen or prescribed after {withinLength} hours',
      monitoring_active_oxygen_patient_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours not prescribed oxygen',
      monitoring_oxygen_stats: ' Oxygen Stats',
      monitoring_oxygen_stats_title: 'Monitoring oxygen stats for dates <b>{dateRange}</b>',
      monitoring_active_oxygen: 'Oxygen',
      safety_active_overview: 'Active Inpatient Safety Indicators',
      safety_active_overview_title: 'Active Inpatient Safety Indicators',
      performance_ward: 'Ward Performance',
      performance: 'Performance',
      performance_ward_title: 'Ward Performance Stats for ward <b>{wardName}</b>',
      parkinsons_early_late_trends: 'Parkinsons Early Late Trend',
      parkinsons_early_late_trends_title: 'Parkinsons early late trend between <b>{dateRange}</b> for ward <b>{wardName}</b>',
      omitted_doses_early_late_trends: 'Early vs Late vs On-Time Trend',
      omitted_doses_early_late_trends_title: 'Early vs Late vs On-Time trend between <b>{dateRange}</b> for ward <b>{wardName}</b> for <b>{routeCodes}</b> routes',
      early_late_trend_tooltip: 'Show Early vs Late vs On-Time trend for last 90 days',
      anticholinergics_cognition: 'Active Inpatients',
      anticholinergics_cognition_title: 'Active inpatients 65 or over with <b>{drugs}</b> {burdenScore} in <b>{wards}</b> for <b>last 7 days</b>',
      aec_last_seven: 'Score last 7 days',
      orders_count: 'Total prescriptions (PRNs)',
      safety_ward_view: 'Medication Safety Ward View',
      safety_ward_view_title: 'Medication safety notifications for <b>{wardName}</b>',
      prescribing_active_prescriptions: 'Active Prescriptions',
      prescribing_active_prescriptions_title: 'Current active prescriptions in <b>{wards}</b> for <b>{drugs}</b> running {orderLength}',
      vte: 'VTE',
      vte_active_patient_indicators: 'Active Patient VTE Indicators',
      vte_active_patient_indicators_title: 'Active patients with <b>&#8805; {threshold}%</b> omitted doses of <b>{drugs}</b> with reason <b>{adminReasons}</b> between <b>{dateRange}</b>',
      omitted_doses_drug_time_sensitivity: 'Drug Punctuality',
      omitted_doses_drug_time_sensitivity_title: 'Drug doses <b>{adminDelta}</b> for dates <b>{dateRange}</b> for <b>{routeCodes}</b> routes',
      parkinsons_drug_time_sensitivity: "'Parkinson's Drug Punctuality'",
      parkinsons_drug_time_sensitivity_title: "Parkinson's drug doses <b>{adminDelta}</b> for dates <b>{dateRange}</b>",
      parkinsons_early_late_doses_league_table_title: 'Parkinsons early vs late vs on-time league table for dates <b>{dateRange}</b>',
      parkinsons_early_late_doses_league_table: "Parkinsons Early vs Late vs On-Time League Table",
      parkinsons_early_late_doses_league_table_sub: "Based on data for the last <b>{dateRangeInterval} days</b>",
      patient_omitted_drug_breakdown: 'Patient Omitted Drug Breakdown',
      patient_omitted_drug_breakdown_title: 'Patient omitted drug breakdown for dates <b>{dateRange}</b> and reason <b>{adminReasons}</b>',
      patient_omitted_drug_breakdown_alt_title: 'Patient omitted drug breakdown for dates <b>{dateRange}</b></b>',
      patient_unvalidated_drug_breakdown: 'Patient Unvalidated Drug Breakdown',
      patient_unvalidated_drug_breakdown_title: 'Unvalidated Prescriptions',
      patient_unvalidated_drug_breakdown_alt_title: 'Patient Active Orders',
      safety_covert_medications: 'Covert Medication Indicators',
      safety_covert_medications_title: 'Safety covert medication indicators for <b>{dateRange}</b>',
      antibiotics_active_orders: 'Active Prescriptions',
      antibiotics_active_orders_title: 'Currently active prescriptions and course length for <b>{formCodes}</b>',
      antibiotics_order_length_breakdown: 'Antibiotics prescription length drug breakdown',
      antibiotics_order_length_breakdown_title: 'Antibiotic prescriptions lasting {orderLength} for the period <b>{dateRange}</b>',
      usage_future: 'Predicted Drug Usage',
      usage_future_title: 'Predicted drug usage for dates <b>{dateRange}</b>',
      brand: 'Triscribe',
      create_user: 'Create User',
      name: 'Name',
      email: 'Email',
      created: 'Created',
      username: 'Username',
      password: 'Password',
      create: 'Create',
      users: 'Users',
      drug_admin: 'Drug Administration',
      upload_file: 'Upload file',
      resume: 'Resume an upload',
      delete_user: 'Delete User',
      delete_user_msg: '<p class="text-center">Are you sure you want to delete this user?</p><p class="text-center"><strong>Their data will be permanently deleted.</strong></p>',
      cancel: 'Cancel',
      ok: 'OK',
      data_admin: 'Data admin',
      user_type: 'User Type',
      admin_badge: 'admin',
      normal_badge: 'normal',
      awaiting_data: 'awaiting data',
      uploading_data: 'uploading data',
      data_uploaded: 'data uploaded',
      processing_data: 'processing data',
      ready: 'ready',
      errored: 'errored',
      user_data: 'User Data',
      tenant_data_fetch_error: 'There were problems fetching the data for this user',
      jac_patient_basic: 'Patient basic',
      jac_patient_spell: 'Patient spell',
      jac_patient_demographic: 'Patient demographics',
      jac_patient_episode: 'Patient episode',
      jac_patient_height: 'Patient height',
      jac_patient_weight: 'Patient weight',
      jac_patient_inr: 'Patient INR',
      jac_patient_transfer_history: 'Patient transfer history',
      jac_med_admin: 'Med admins',
      jac_med_order: 'Med orders',
      jac_med_order_dose: 'Med order doses',
      jac_med_order_suspend: 'Med order suspend',
      jac_med_order_medman: 'Med order medman',
      jac_med_order_verify: 'Med order verify',
      jac_med_spell_order: 'Med spell order',
      jac_drug_basic_common: 'Drug basic common',
      jac_drug_prescribing: 'Drug prescribing',
      jac_drug_speccon: 'Drug special conditions',
      jac_active_inpatient: 'Active inpatient',
      jac_active_spell: 'Active spell',
      jac_ward: 'Wards',
      jac_system_user: 'System User',
      jac_frequency: 'Frequency',
      jac_cost_centre_group: 'Ward Groups',
      reset: 'Reset',
      admin_dashboard: 'Admin Dashboard',
      drug_mappings: 'Drug Mappings',
      drug_mapping: 'Drug Mapping',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      user_security: 'User Security',
      set_password: 'Set Password',
      login: 'Login',
      logout: 'Logout',
      remember_me: 'Remember me',
      number_of_administrations: 'Number of administrations',
      administrations: 'Administrations',
      orders: 'Orders',
      date_range: 'Date range',
      bnf_chapter: 'BNF chapter',
      apply_filters: 'Apply filters',
      administration_events: 'Administration events',
      variants: 'Details',
      show: 'Show',
      process_data: 'Process data',
      overall_administrations: 'Overall administrations',
      ward_administrations: 'Ward administrations',
      drug_route: 'Drug administration routes',
      status: 'Status',
      jac_status: 'JAC Status',
      tie_status: 'TIE Status',
      mv_status: 'MV Status',
      upload_error: 'There was a problem uploading the file',
      prescription_data: 'Prescription data',
      jac: 'JAC',
      tie: 'TIE',
      tie_consultant_specialty: 'Consultant Specialty',
      resetting: 'resetting',
      hospital_admin: 'Hospital admin',
      new_hospital: 'New Hospital',
      dmd: 'DM+D',
      dmd_manage: 'DMD Download Management',
      operations: 'Operations',
      options: 'Options',
      advanced_options: 'Advanced options',
      reset_dmd: 'Reset dm+d',
      fetch_dmd: 'Fetch dm+d',
      reset_snomed: 'Reset snomed',
      fetch_snomed: 'Fetch snomed definitions',
      snomed: 'Snomed',
      info: 'Info',
      dmd_sup: 'DM+D supplementary',
      reset_dmd_sup: 'Reset dm+d supplementary',
      fetch_dmd_sup: 'Fetch dm+d supplementary',
      dmd_version: 'dm+d version',
      dmd_date: 'dm+d date',
      dmd_sup_version: 'dm+d supplementary version',
      dmd_sup_date: 'dm+d supplementary date',
      snomed_version: 'Snomed version',
      snomed_date: 'Snomed date',
      dmd_snomed: 'DM+D and Snomed',
      dict: 'Dictionary',
      select: 'Select',
      regen_dict: 'Regenerate dictionary',
      regen_specialty_codes: 'Specialty codes',
      specialty_code_date: 'Specialty codes sync date',
      resetDmdTitle: 'Reset dm+d',
      resetDmdMsg: 'This will reset the dm+d version and will force the dm+d data to be fetched when next required - it does not delete any data',
      resetDmdSupTitle: 'Reset dm+d supplementary files',
      resetDmdSupMsg: 'This will reset the dm+d supplementary version and will force the dm+d supplementary data to be fetched when next required - it does not delete any data',
      resetSnomedTitle: 'Reset snomed',
      resetSnomedMsg: 'This will reset the snomed version and will force the snomed trade family data to be fetched when next required - it does not delete any data',
      fetchDmdTitle: 'Fetch dmd+d',
      fetchDmdMsg: 'Fetch the latest version of dm+d. This will overwrite the existing data if the version number or date are different.',
      fetchDmdSupTitle: 'Fetch dmd+d supplementary files',
      fetchDmdSupMsg: 'Fetch the latest version of dm+d supplementary files. This will overwrite the existing data if the version number or date are different.',
      fetchSnomedTitle: 'Fetch snomed',
      fetchSnomedMsg: 'Fetch the latest version of snomed for trade family names. This will overwrite the existing data if the version number or date are different.',
      fetch_all_mappings: 'Fetch all mappings',
      fetch_unverified_mappings: 'Fetch unverified mappings',
      mapUncertainDrugsTitle: 'Remap uncertain drugs',
      mapUncertainDrugsMsg: '<p class="text-center">Are you sure?</p><p class="text-center">Any drugs that do not have a 100% certain match will be be overwritten and an automatic drug mapping undertaken.</p>',
      are_you_sure_msg: '<p class="text-center">Are you sure?</p>',
      admin_email: 'Admin email',
      hospitals: 'Hospitals',
      delete_hospital: 'Delete Hospital',
      delete_hospital_msg: '<p class="text-center">Are you sure you want to delete this hospital?</p><p class="text-center"><strong>Their data will be permanently deleted.</strong></p>',
      data_tab_header: 'Data',
      security_tab_header: 'Security',
      add: 'Add',
      enter_hospital_details: '<strong>Enter</strong> the hospitals\' details',
      manage_hospitals_header: 'Manage - <span class="fw-semi-bold">Hospitals</span>',
      manage_hospitals: 'Manage Hospitals',
      new_hospital_header: '<strong>New</strong> Hospital',
      home: 'Home',
      antibiotics: 'Antibiotics',
      prescribing: 'Prescribing',
      hard_map_drugs: 'Reset and map drugs',
      rebuild_administrations: 'Rebuild Administration Data',
      rebuild_orders: 'Rebuild Order Data',
      rebuild_upcoming_administrations: 'Rebuild Upcoming Administrations Data',
      rebuild_stock_levels: 'Rebuild Stock Level Data',
      rebuild_stock_movements: 'Rebuild Stock Movement Data',
      reset_data: 'Reset Data',
      all_data: 'All Data',
      reset_all_data: 'Reset all data',
      reset_jac_data: 'Reset JAC data',
      reset_tie_data: 'Reset TIE data',
      reset_mv_data: 'Reset Metavision Data',
      reset_jac_data_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center"><strong>All JAC prescription data will be destroyed!</strong></p>',
      reset_tie_data_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center"><strong>All TIE data will be destroyed!</strong></p>',
      reset_mv_data_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center"><strong>All Metavision data will be destroyed!</strong></p>',
      reset_jac_stock_data_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center"><strong>All JAC stock data will be destroyed!</strong></p>',
      reset_all_data_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center"><strong>All data will be destroyed!</strong></p>',
      usage: 'Usage',
      user_admin: 'User admin',
      antimicrobials: 'Antimicrobials',
      antibiotics_ddd: 'Antibiotics DDD',
      antibiotics_dot: 'Antibiotics DOT',
      dots: 'DOTs',
      label_dots: '{{TIME}} DOTs',
      ddds: 'DDDs',
      label_ddds: '{{TIME}} DDDs',
      dashboard_home: 'Dashboard Home',
      log_out: 'Log out',
      reset_and_map_drugs: 'Reset and map drugs',
      reset_and_map_drugs_hard_msg: '<strong class="text-center">Are you sure?</strong> <br><strong>All</strong> existing mappings will be <strong>deleted</strong> including any manual mappings and those that have been verified.',
      reset_and_map_drugs_soft_msg: 'Map any drugs that have not been previously mapped - existing mappings will not be affected?',
      dm_info: 'Info',
      dm_confidence: 'Confidence',
      dm_status: 'Status',
      dm_type: 'Type',
      verify: 'Verify',
      change: 'Change',
      update: 'Update',
      dm_source_drug: 'Source drug',
      dm_mapped_drug: 'Mapped drug',
      previous: 'Previous',
      next: 'Next',
      page: 'Page',
      rows: 'rows',
      num_prescriptions: 'Number of Prescriptions',
      ward_prescribed: 'Ward Prescribed',
      refresh_prescriptions: 'Refresh Prescription Data',
      patients: 'Patients',
      refresh_patients: 'Refresh Patient Data',
      dates: 'Dates',
      hard_map_drug: 'Reset and map drugs',
      hard_map_drug_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center">Remapping the drugs will <b>remove all</b> drug mappings for this hospital, <b>including</b> any manual mappings.</p>',
      route_site_mapping: 'Route/Site Mapping',
      hard_map_route_site: 'Reset and map routes and sites',
      hard_map_route_site_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center">Remapping will remap route/site mappings for this hospital.</p>',
      unit_mapping: 'Unit Mapping',
      hard_map_all: 'Reset ALL mappings and rebuild data',
      hard_map_all_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center">This will remove all existing mappings and rebuild the data tables causing dashboards for this hospital to be empty while it rebuilds</p>',
      hard_map_unit: 'Reset and map units',
      hard_map_unit_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center">Remapping will remap unit mappings for this hospital.</p>',
      user_admin_tab_header: 'User Admin',
      manage_users_header: 'Manage Users',
      new_user: 'New User',
      new_user_header: '<strong>New</strong> User',
      enter_user_details: '<strong>Enter</strong> the users\' details',
      force_change: 'Password change on first login',
      first_login: 'Onboarding',
      welcome_link: 'Welcome Link',
      onboarding_msg: '<p>It looks like you\'ve been signed up for a Triscribe account.</p><p>Your username is <strong>{username}</strong>.</p><p>To get started using Triscribe please first set a password below:</p>',
      copy: 'Copy',
      copied: 'Copied',
      force_password_change_expired: '<p>The link are trying to use has either expired or you have already setup your Triscribe account.</p><p>If you have set up your account then enter your login details below. If not please contact the person that sent you the link to get them to send you a fresh one.</p>',
      welcome: 'Welcome',
      hi: 'Hi',
      please_update_password: '<b>Please</b> update your password',
      update_password_msg: '<p>It been requested that you update your password. Please update your password by filling in the form below. Thanks.</p>',
      existing_password: 'Existing password',
      new_password: 'New password',
      antibiotics_route: 'Antibiotic Routes',
      total_admins: 'Number of Administrations',
      total_available_per: '% of Available Doses',
      iv: 'IV Route Info',
      omitted_doses: 'Omitted Doses',
      omitted_doses_overview: 'Omitted Doses Overview',
      omitted_league_table: 'Omitted Doses League Table',
      early_late_doses_league_table: 'Early vs On Time vs Late League Table',
      forgotten_password_msg: 'Enter your email address or username below and you will be emailed with a link explaining how to set a new password.',
      submit: 'Submit',
      forgotten_password: 'Forgotten your password?',
      password_reset_success: 'Successfully processed password reset request',
      password_reset_fail: 'Failed to initiate password reset request. Please try again.',
      password_reset_msg: 'Enter your new password below:',
      standard_error: 'There was a problem processing your request. Please try again. If the problem persists contact info@triscribe.net.',
      password_set: 'Password set',
      advanced_hospital_settings: 'Advanced hospital settings',
      aws_bucket_name: 'AWS upload bucket',
      upload_filename_format: 'Upload filename format',
      aws_settings: 'AWS Settings',
      extractor_tool: 'Extractor Tool',
      save: 'Save',
      imported_data: 'Imported Data',
      denorm_mapping_data: 'Denorm/Mapping Data',
      data_import: 'Data Import',
      last_update: 'Last update',
      device_id: 'Device Id',
      last_updated: 'Last Updated',
      notifications: 'Notifications',
      extractors: 'Extractors',
      config: 'Config',
      save_and_publish_config: 'Save and publish config',
      delete: 'Delete',
      extractor_version: 'Extractor version',
      config_version: 'Config version',
      processing: 'Processing',
      done: 'Done',
      no_handler: 'No handler',
      latest_notifications: 'Latest notifications',
      type: 'Type',
      date_grouping: 'Date grouping',
      filters: 'Filters',
      apply: 'Apply',
      download: 'Download',
      rebuild: 'Rebuild',
      recalculate: 'Recalculate',
      remap: 'Remap',
      omitted_doses_overview_hospital_definition: 'Hospital definition',
      omitted_doses_overview_standard_definition: 'Standard definition',
      data_logs: 'Data input logs',
      data_log: 'Data Log',
      from: 'From',
      version: 'Version',
      waiting: 'waiting',
      downloaded: 'downloaded',
      loading: 'loading',
      delete_data_log: 'Delete data log',
      delete_data_log_msg: 'Deleting this data log will mean that any new data log with the same version can be reloaded. It will not delete any actual data.',
      delete_notification: 'Delete notification',
      delete_notification_msg: 'Deleting the notification will prevent any actions triggered by it from happening if it has not already been processed.',
      errors: 'Errors',
      date: 'Date',
      error_data: 'Error data',
      message: 'Message',
      view_error_data: 'View error data',
      delete_all_like: 'Delete all like this',
      delete_error: 'Delete error',
      are_you_sure: "Are you sure? Once it's gone it's gone!",
      delete_error_like_this: 'Delete all errors that look like this',
      delete_error_like_this_msg: 'Are you sure? All errors that look similar to this one will be removed from the database and cannot be recovered.',
      omitted_doses_ward_view: "Ward View (Heat Map)",
      omitted_doses_league_table_view: "League Table",
      omitted_doses_ward_view_title: "Omitted Doses for <b>{wardName}</b>",
      omitted_doses_ward_view_breakdown_title: "Date selected: {date}",
      omitted_doses_admin_reasons_title: "Omitted Reasons for <b>{drugs}</b> between <b>{dateRange}</b> for <b>{wards}</b>",
      omitted_doses_tt: "Omitted doses",
      antibiotics_order_length: "Prescription Length",
      num: "#",
      bnfs: "BNFs",
      rebuild_bnfs: "Update BNF cache",
      anticholinergics: "Anticholinergics",
      anticholinergics_overview: "Anticholinergics Overview (Heat Map)",
      anticholinergics_overview_title: "Patients <b>{aged}</b> with Anticholinergic Score <b>{anticholinergics_score}</b> for wards <b>{wards}</b>",
      anticholinergics_breakdown_title: "Patients <b>{aged}</b> for dates <b>{dateRange}</b> and wards <b>{wards}</b>",
      anticholinergics_patients_title: "Patients <b>{aged}</b> for dates <b>{dateRange}</b> and wards <b>{wards}</b>",
      anticholinergics_tt: "Patients",
      anticholinergics_breakdown: "Anticholinergics Breakdown",
      number_of_patients: "Number of Patients",
      omitted_doses_admin_reasons: "Omitted Reasons Ward View",
      add_range: "Add Range",
      percentage_of_total_orders: "% of total prescriptions",
      num_of_orders: "Number of prescriptions",
      order_length_days: "Prescription length (days)",
      order_length_as_totals: "Prescription length {{xVal}} as % of total orders",
      admin_burden_date_score: "Ward oral meds admin burden score for {{xVal}}",
      fetch_specialty_codes: "Fetch specialty codes",
      specialty_codes: "Specialty codes",
      antibiotics_order_specialty: 'Prescriptions by Specialty',
      rerun_admins_denorm: 'Rerun admins denorm',
      rerun_data_log_denorm: 'Rerun denormalisation using a data log',
      rerun_data_log_denorm_msg: 'Reruning the denormalisation process for {table} can take a long time if you have many months of data. Are you sure?',
      omitted_doses_admin_reasons_ward_compare: 'Omitted Reason Ward Compare',
      percent_total_ward_admins: '% of total ward admins',
      omitted_doses_admin_reasons_ward_compare_title: 'Comparison of wards <b>{wards}</b> for omitted reason <b>{adminReason}</b>',
      jac_system_drug: 'System Drug',
      bm: 'Bettermeds',
      bm_status: 'Bettermeds Status',
      bm_administration: 'Administrations',
      bm_administration_task: 'Administration Tasks',
      bm_admission_med: 'Admission Meds',
      bm_allergy: 'Allergies',
      bm_discharge_med: 'Discharge Meds',
      bm_inpatient_order: 'Inpatient Orders',
      bm_med: 'Meds',
      bm_outpatient_order: 'Outpatient Orders',
      bm_patient: 'Patients',
      bm_pharmacist_review: 'Pharmacist Reviews',
      bm_reconciliation: 'Reconciliations',
      bm_supply_request_action: 'Supply Request Actions',
      reset_bm_data: 'Reset Bettermeds data',
      mv: 'Metavision',
      mv_logical_unit: 'Logical Units',
      mv_ord_standard_time: 'Ord Standard Times',
      mv_order_category: 'Order Categories',
      mv_order_entry: 'Order Entries',
      mv_orders_dose_action_reason: 'Orders Dose Action Reasons',
      mv_orders_dose_form: 'Orders Dose Forms',
      mv_orders_location: 'Orders Locations',
      mv_orders_medication_brand: 'Orders Medication Brands',
      mv_orders_medication: 'Orders Medications',
      mv_orders_route: 'Orders Routes',
      mv_unit_group: 'Unit Groups',
      mv_parameter: 'Parameters',
      mv_patient: 'Patients',
      mv_patient_status: 'Patient Status',
      mv_patient_tracking: 'Patient Tracking',
      mv_pending_order: 'Pending Orders',
      mv_pending_range_signal: 'Pending Range Signals',
      mv_planned_order: 'Planned Orders',
      mv_planned_orders_history: 'Planned Orders History',
      mv_range_signal: 'Range Signals',
      mv_user: 'Users',
      patient_mapping: 'Patient Mapping',
      user_mapping: 'User Mapping',
      order_mapping: 'Order Mapping',
      location_mapping: 'Location Mapping',
      epm: 'EPM',
      groups: 'Groups',
      custom_list: 'Custom List',
      selected_drugs: 'Selected drugs:',
      select_drugs: 'Select drugs',
      selected_wards: 'Selected wards:',
      select_wards: 'Select wards',
      name_your_group: 'Please name your group:',
      create_drug_group: 'Create drug group',
      group_created: 'Group created',
      manage_drug_groups: 'Manage Drug Groups',
      my_drug_groups: 'My Drug Groups',
      delete_group: 'Delete drug group',
      delete_group_msg: 'Are you sure you want to delete this drug group?',
      last_week: 'Last week',
      last_month: 'Last month',
      last_quarter: 'Last quarter',
      last_year: 'Last year',
      predefined_dates: 'Predefined dates',
      parkinsons: "Parkinson's",
      parkinsons_counts: "Parkinson's Drug Administrations",
      parkinsons_delayed_counts: "Parkinson's Delayed Administration Counts",
      parkinsons_delayed_timeline: "Parkinson's Delayed Administrations Time Series",
      usage_admin_counts: "Administration Counts",
      usage_overview: "Drug Usage (Heat Map)",
      drugs: 'Drugs',
      wards: 'Wards',
      usage_admin_counts_title: 'Administration totals for <b>{drugs}</b> between <b>{dateRange}</b>',
      usage_overview_title: 'Usage statistics between <b>{dateRange}</b>',
      usage_ward_title: 'Usage statistics {dateGrouping} between <b>{dateRange}</b> for <b>{drugs}</b>',
      usage_ward_drugs_title: 'Usage statistics {dateGrouping} between <b>{dateRange}</b> for <b>{drugs}</b>',
      usage_ward_drugs_table: 'Ward Drug Usage Table',
      usage_ward_drugs_table_title: 'Ward drug usage levels',
      usage_ward_drugs_table_sub: "Based on data for the last <b>{dateRangeInterval} days</b>",
      antibiotics_dot_title: 'DOTs {dateGrouping} for <b>{drugs}</b> </v>between <b>{dateRange}</b> {bedDays} for <b>{wards}</b>',
      antibiotics_ddd_title: 'DDDs {dateGrouping} for <b>{drugs}</b> between <b>{dateRange}</b> {bedDays} for <b>{wards}</b>',
      antibiotics_order_length_title: 'Prescription length of <b>{drugs}</b> as % of total <b>{drugs}</b> orders between <b>{dateRange}',
      antibiotics_route_title: 'Route breakdown of <b>{routes}</b> between <b>{dateRange}',
      hospital_admissions_title: 'Hospital admissions <b>{dateGrouping}</b> between <b>{dateRange}</b>',
      hospital_bed_days_title: 'Number of bed days <b>{dateGrouping}</b> between <b>{dateRange}</b>',
      stock_history_title: 'Stock levels <b>{dateGrouping}</b> between <b>{dateRange}</b>',
      stock_movement_title: 'Stock movements <b>{dateGrouping}</b> between <b>{dateRange}</b> for wards <b>{wards}</b>',
      parkinsons_delayed_counts_title: "Drugs with more than one delayed administration from <b>{wards}</b> between <b>{dateRange}</b>",
      parkinsons_delayed_timeline_title: "Delayed Parkinson's administrations for <b>{wards}</b> between <b>{dateRange}</b>",
      parkinsons_counts_title: "Parkinson's administration counts from <b>{wards}</b> between <b>{dateRange}</b>",
      admin_burden_overview: "Burden Overview",
      admin_burden_overview_title: "{burdenCountGrouping} admin burden overview between <b>{dateRange}</b> for wards <b>{wards}</b>",
      admin_burden_counts: "Burden Group Levels",
      admin_burden_counts_title: "{burdenCountGrouping} levels for <b>{groupId}</b> between <b>{dateRange}</b> for wards <b>{wards}</b>",
      admin_burden_scores: "Burden Group Scores",
      admin_burden_scores_title: "{burdenCountGrouping} scores for <b>{groupId}</b> between <b>{dateRange}</b> for wards <b>{wards}</b>",
      unknown: 'Unknown',
      delayed_administrations: 'Number of delayed administrations',
      group_name_error: 'You must supply a name for the group',
      percentage_of_total_admins: '% of total administrations',
      admin_burden: 'Admin Burden',
      upcoming_administrations: 'Upcoming Administrations',
      jac_stock: 'JAC Stock',
      jac_stock_status: 'JAC Stock Status',
      jac_stock_cost_centre: 'Cost Centre',
      jac_stock_drug_location: 'Drug Location',
      jac_stock_drug_pack: 'Drug Pack',
      jac_stock_location: 'Location',
      jac_stock_order_item: 'Order Items',
      jac_stock_order_items_deliv: 'Order Items Delivered',
      jac_stock_supplier: 'Supplier',
      jac_stock_x_daily_issue: 'Daily Issues',
      jac_stock_x_order: 'Stock Orders',
      jac_stock_x_daily_credited_issue: 'Credited Daily Issues',
      reset_jac_stock_data: 'Reset JAC Stock data',
      stock_levels: 'Stock Levels',
      stock_movements: 'Stock Movements',
      antibiotics_stock_shortages: 'Stock Shortages',
      stock: 'Stock',
      dose_units: 'Dose units',
      stock_history: 'Stock History',
      stock_movement: 'Stock Movement',
      antibiotics_stock_shortage: 'Stock Shortage',
      packs_issued_net: 'Packs Issued (net)',
      hospital_activity: 'Hospital Activity',
      hospital_admissions: 'Admissions',
      admitted_count: 'Number of admissions',
      hospital_spell_stats: 'Hospital Spell Stats',
      score: 'Score',
      ward_admin_stats: 'Ward Admin Stats',
      recalculate_ward_admin_stats: 'Recalculate ward admin stats',
      recalculate_ward_admin_stats_msg: '<b>Are you sure</b> that you want to recalculate? This may take some time?',
      admin_burden_score: 'Admin Burden Score',
      admin_counts: 'Administration Counts',
      drug_and_pack: 'Drug and Pack description',
      issued: 'Issued',
      returned: 'Returned',
      packs: 'Packs',
      items: 'Items',
      net: 'net',
      data_operations: 'Data Operations',
      source_table: 'Data Source',
      rebuild_wizard: 'Rebuild Wizard',
      rebuild_selection: 'Select the tables you wish to rebuild:',
      starting_system: 'Starting {system}',
      working_chunk: 'Chunking {system}: {stop-date} - {start-date}',
      finished_chunk: 'Done chunk {system}: {stop-date} - {start-date}',
      waiting_post: 'Awaiting post-process',
      postprocessing: 'Post-processing',
      replacing_data: 'Replacing Data',
      data_replaced: 'Data Replaced',
      removing_old_data: 'Removing Old Data',
      complete: 'Complete',
      data_ready: 'Data Ready',
      run: 'Run',
      data_op_action: 'Run data operation action...',
      restore_defaults: 'Restore defaults',
      export: 'Export',
      export_format: 'Export format',
      no_data_export: 'This is no data available to export',
      specialty: 'Consultant Specialty',
      date_range_start_invalid: '',
      date_range_end_invalid: '',
      adt_mapping: 'ADT Mapping',
      hard_map_adt_msg: '<h5 class="text-center">Are you sure?</h5><p class="text-center">Remapping ADTs will completely recalculate ADT mappings for this hospital.</p>',
      hospital_bed_days: 'Bed Days',
      bed_days: 'Bed Days',
      percent_total_drug_admin: "% of total admins delayed",
      total_admin: 'Total number of administrations',
      good_news_counts: "Great news! We did not find any Parkinson's medications that were delayed",
      pos: "Pos",
      percent_reason: 'Reason as % total administrations on ward',
      ward: "Ward",
      administered_ward: "Administered Ward",
      antibiotics_shortage: "Antibiotics Shortage",
      shortage_title: "Potential Shortages Based on Use and Upcoming Administrations",
      drug_name: "Drug Name",
      drug_form: "Drug Form",
      action: "Action",
      shortage_score: "Score",
      stock_orders: "Stock Orders",
      stock_supplier_stats: "Stock Supplier Stats",
      run_level: "Run level:",
      no_data: "No data found that matches the filters selected",
      stock_drug_stats: "Stock Drug Stats",
      db_vacuum: "Manual DB Vacuum",
      vacuum_selection: "Select the type of tables to vacuum:",
      stock_status: "Stock Status",
      stock_days: "Stock days",
      lead_days: "Lead days",
      active_orders: "Active orders",
      late_orders: "Late orders",
      amount_in_stock: "Amount in stock",
      daily_use: "Amount used daily",
      omitted_doses_league_table: "Omitted Doses League Table",
      omitted_doses_league_table_sub: "Based on data for the last <b>{dateRangeInterval} days</b>",
      omitted_doses_drug_reason_title: "Omitted drugs for reason <b>{adminReason}</b> for ward <b>{wardName}</b> for last <b>{dateRangeInterval} days</b>",
      omitted_doses_administrator_reason_title: "Administrators for reason <b>{adminReason}</b> for ward <b>{wardName}</b> for last <b>{dateRangeInterval} days</b>",
      omitted_doses_early_late_doses_league_table: "Early vs Late vs On-Time League Table",
      omitted_doses_early_late_doses_league_table_sub: "Based on data from <b>{routeCodes}</b> routes for the last <b>{dateRangeInterval} days</b>",
      number_of_omitted_doses: 'Omitted administrations',
      reason_breakdown: 'View breakdown info',
      drug_breakdown: 'Drug breakdown',
      hourly_breakdown: 'Hourly breakdown',
      administrator_breakdown: 'Administrator breakdown',
      detailed_information: 'Detailed Information',
      my_settings: 'My Settings',
      settings: 'Settings',
      hospital_settings: '{hospital}\'s Settings',
      hospital_dashboards: '{hospital}\'s Dashboards',
      filter_settings: 'Filter Settings',
      manage_default_filters: 'Manage Default Filters',
      general_filter_settings: 'General Filter Settings',
      ward_or_group: 'Default to <b>ward</b> or <b>ward group</b>',
      default_ward: 'Default ward(s)',
      default_ward_group: 'Default ward group',
      filter_name: 'Filter Name',
      filter_value: 'Value',
      what_filters: 'When loading dashboard use',
      default_values: 'Default values',
      last_used_values: 'Last used values',
      save_default_filters: 'Save default filters',
      save_general_filters: 'Save general filter settings',
      ward_group: 'Ward group',
      default_ward_groups: 'Default ward group',
      extra_user_filter_details: 'Default filter settings',
      save_success: 'Successfully saved',
      save_fail: 'Unable to save changes',
      roles: 'Roles',
      custom: 'Custom',
      next_30_days: 'Next 30 days',
      next_4_days: 'Next 4 days',
      next_7_days: 'Next 7 days',
      next_3_days: 'Next 3 days',
      next_2_days: 'Next 2 days',
      tomorrow: 'Tomorrow',
      today: 'Today',
      last_3_days: 'Last 3 days',
      last_7_days: 'Last 7 days',
      last_30_days: 'Last 30 days',
      last_90_days: 'Last 90 days',
      last_365_days: 'Last 365 days',
      default_date_range_type: 'Default date selection',
      phs_administration: 'PHS Administrations',
      phs_prescription: 'PHS Prescriptions',
      reset_phs_data: 'Reset PHS Data',
      phs_status: 'PHS Status',
      export_requests: 'Export requests',
      available_exports: 'Available exports',
      phs: 'PHS',
      new_export: 'New Export',
      date_range_type: 'Default date range',
      safety_impact_indicator: 'Safety Impact Indicators',
      safety: 'Safety Indicators',
      safety_impact_indicator_title: "Safety Indicator Passes between <b>{dateRange}</b>",
      pass_percent: '% Passed',
      last_sign_in: 'Last sign in',
      safety_indicator_stats: "Safety Indicator Stats",
      safety_impact_indicator_breakdown_title: "Safety Indicator Breakdown for <b>{date}</b>",
      indicator_name: 'Indicator Name',
      admins_passed: 'Admins passed',
      admins_failed: 'Admins failed',
      orders_passed: 'Prescriptions passed',
      orders_failed: 'Prescriptions failed',
      export_users: 'Export Users',
      admin_burden_settings: 'Admin Burden Settings',
      manage_users: 'Manage Hospital Users',
      manage_data: 'Manage Hospital Data',
      manage_extractors: 'Manage Hospital Extractors',
      manage_hospital: 'Manage {hospital}',
      hospital_list: 'Hospital List',
      burden_drug_group_weight: 'Admin Burden Drug Group Weighting',
      drug_group: 'Drug group',
      form_group: 'Form group',
      route_group: 'Route group',
      burden_level: 'Burden level',
      new_burden_weighting: 'New Weighting',
      group: 'Group',
      start_typing_group: 'Start typing the group name',
      create_group: 'Create Group',
      create_drug_group_msg: 'Search for the drugs that you wish to add to the drug group below:',
      group_name: 'Group name',
      search_for_drugs: 'Start typing to search for drugs',
      your_drug_group_name: 'Give your drug group a name',
      display_settings: 'Display Settings',
      what_chart_size: 'When displaying charts',
      fit_to_page: 'Show chart/title/axis without scrolling',
      fit_to_viewport: 'Use as much space as possible to draw charts',
      chart_display_settings: 'Chart display settings',
      burden_count_warning: 'You may want to select a larger date range above',
      burden_group: 'Drug group type',
      hard_map_location: 'Reset all location mappings',
      hard_map_location_msg: 'This will reset all location mappings and rebuild default ward group',
      medchart_admin_episode: 'Admin Episodes',
      medchart_admin_event: 'Admin Events',
      medchart_location: 'Locations',
      medchart_location_stay: 'Location Stays',
      medchart_medication: 'Medications',
      medchart_medication_dose: 'Medication Doses',
      medchart_patient_admission: 'Patient Admission',
      medchart_patient_admission_group: 'Patient Admission Group',
      medchart_patient_detail: 'Patient Details',
      medchart_patient_identifier: 'Patient Identifiers',
      medchart_user: 'Users',
      medchart: 'Medchart',
      medchart_status: 'Medchart Status',
      medchart_medication_dose_datetime: 'Medication Dose Datetime',
      medchart_medication_dose_sched: 'Medication Dose Schedules',
      medchart_medication_vdo_dose: 'Medication VDOs Doses',
      medchart_cds_medication_group: 'CDS Medication Groups',
      medchart_cds_group: 'CDS Groups',
      medchart_cds_medication: 'CDS Medications',
      medchart_cds_detected_issue: 'CDS Detect Issues',
      medchart_udm_cds_local_interaction: 'UDM CDS Local Interaction',
      medchart_udm_cds_interaction_class: 'UDM CDS Interaction Classes',
      medchart_udm_cds_allergy_class: 'UDM CDS Allergy Classes',
      medchart_udm_cds_rule: 'UDM CDS Rules',
      medchart_udm_cds_dose_range: 'UDM CDS Dose Ranges',
      medchart_udm_cds_allergy_class_substance: 'UDM CDS Allergy Class Substances',
      medchart_udm_cds_interaction_class_substance: 'UDM CDS Interaction Class Substances',
      medchart_merge_patient_record: 'Merge Patient Records',
      medchart_udm_formulary: 'UDM Formulary',
      medchart_udm_formulary_ac_form: 'UDM Formulary AC Form',
      medchart_udm_formulary_product: 'UDM Formulary Product',
      medchart_udm_product: 'UDM Product',
      medchart_udm_ac_form: 'UDM AC Form',
      medchart_udm_product_ac_form: 'UDM Product AC Form',
      medchart_udm_ac: 'UDM AC',
      medchart_udm_ais: 'UDM AIS',
      medchart_diagnosis: 'Diagnosis',
      reset_medchart_data: 'Reset Medchart Data',
      reset_medchart_data_msg: '<h5 className="text-center">Are you sure?</h5><p className="text-center"><strong>All Medchart prescription data will be destroyed!</strong></p>',
      acb_score: 'ACB',
      administered_date: 'Administered Date',
      administered_time: 'Time',
      patient_sex: 'Sex',
      patient_age: 'Age',
      anticholinergics_patients: 'Anticholinergics Patients',
      acb_timeline: 'ACB Score Timeline',
      drug: 'Drug',
      acb_total: 'ACB Total',
      yesterday: 'Yesterday',
      antibiotics_admin_specialty: 'Administrations by Specialty',
      num_of_admins: "Number of administrations",
      select_admin_reason: 'Select admin reason',
      selected_admin_reasons: 'Selected admin reasons',
      usage_ward: "Compare Ward Usage (Total)",
      usage_ward_drugs: "Compare Ward Usage (Per Drug)",
      prescribing_opioids: "Opioid Prescribing Statistics",
      prescribing_opioids_sub: "For the last <b>{dateRangeInterval} days</b>",
      ascribe_customer: 'Customers',
      ascribe_product: 'Products',
      ascribe_product_stock: 'Product Stocks',
      ascribe_translog: 'Translogs',
      ascribe_order_log: 'Order Logs',
      ascribe_order: 'Orders',
      ascribe_site: 'Sites',
      ascribe_supplier: 'Suppliers',
      ascribe_ward_stock_list: 'Ward Stock Lists',
      ascribe_supplier_profile: 'Supplier Profiles',
      ascribe: 'Ascribe',
      reset_ascribe_data: 'Reset Ascribe Data',
      reset_ascribe_data_msg: '<h5 className="text-center">Are you sure?</h5><p className="text-center"><strong>All Ascribe data will be destroyed!</strong></p>',
      ascribe_status: 'Ascribe Status',
      hospital: 'Hospital Activity',
      form: 'Form',
      dose: 'Dose',
      prescription_length: 'Prescription Length',
      burden_score: 'Burden score',
      active_inpatients: 'Active Inpatient',
      historical: 'Historical',
      location_bed_mapping: 'Location Bed Mapping',
      course_length_days: 'Course Length (days)',
      bed: 'Bed',
      room: 'Room',
      hard_map_location_bed: 'Reset all bed mappings',
      hard_map_location_bed_msg: 'This will reset all bed mappings. Active inpatients may point to invalid mappings',
      indication: 'Indication',
      all_forms: 'all forms',
      percentage_refused: 'Percentage of total doses refused by patient',
      show_patient_drug_breakdown: 'Show Patient Drug Breakdown',
      no_covert_indicators: 'No patients found over threshold',
      reason: 'Omitted reason',
      num_omitted_doses: 'Number of omitted doses',
      percentage_of_total: 'Percentage of total doses',
      no_vte_indicators: 'No patients found with indicator over threshold',
      percentage_omitted_drug: 'Percentage of omitted {drug} doses',
      order_start_date: 'Order start date',
      order_end_date: 'Order end date',
      event_relativity: 'Data relative to',
      avg_days: 'Avg prescription length (days)',
      avg_pres_len: 'Avg prescription length',
      medication_safety_notifications: 'Medication Safety Notifications',
      patient_name: 'Patient Name',
      hospital_number: 'Hospital Number',
      drug_score: 'Drug score',
      running_total: 'Running total',
      ward_administered: 'Ward administered',
      administrators: 'Administrators',
      chart_type: "Chart type",
      last_1_days: 'Yesterday',
      last_1_years: 'Last year',
      indicator: 'Indicator',
      count: 'Count',
      safety_indicator_guide: 'Safety Indicators Guide',
      description: 'Description',
      opioids: 'Opioids',
      percentage_of_total_oxygen_orders: '% of total oxygen prescriptions',
      monitoring_oxygen_history_tooltip_header: 'Oxygen status for patients admitted on <b>{{date}}</b><hr>',
      admission_date: 'Patient admission date',
      number_of_admissions: 'Number of admissions',
      monitoring_active_oxygen_prescribed_tooltip: 'Prescribed',
      monitoring_active_oxygen_prescribed_today_tooltip: 'Prescribed today',
      monitoring_active_oxygen_prescribed_within_tooltip: 'Prescribed within {withinLengthHours}',
      monitoring_active_oxygen_prescribed_today_within_tooltip: 'Prescribed today within {withinLengthHours}',
      monitoring_active_oxygen_not_prescribed_tooltip: 'Not prescribed',
      monitoring_active_oxygen_not_prescribed_today_tooltip: 'Not prescribed today',
      monitoring_active_oxygen_not_prescribed_within_tooltip: 'Not prescribed/Prescribed outwith {withinLengthHours}',
      monitoring_active_oxygen_not_prescribed_today_within_tooltip: 'Not prescribed/Prescribed outwith {withinLengthHours} today',
      monitoring_oxygen_history_prescribed_tooltip: 'Prescribed',
      monitoring_oxygen_history_prescribed_within_tooltip: 'Prescribed within {withinLengthHours}',
      monitoring_oxygen_history_not_prescribed_tooltip: 'Not prescribed',
      monitoring_oxygen_history_not_prescribed_within_tooltip: 'Not prescribed/Prescribed outwith {withinLengthHours}',
      compliant: 'Compliant',
      oxygen_ward_guide: 'Oxygen Ward Guide',
      reason_reason: 'Reason',
      monitoring: 'Monitoring',
      monitoring_active_oxygen_within_title: "[[[b| ALL INPATIENTS]]]\nOxygen prescribed {withinLength}\nfor admissions {stayLength}",
      monitoring_active_oxygen_title: "[[[b| ALL INPATIENTS]]]\nOxygen prescribed\nfor admissions {stayLength}",
      monitoring_active_oxygen_within_today_title: '[[[b| ADMITTED TODAY]]]\nOxygen prescribed {withinLength}\nfor admissions {stayLength}',
      monitoring_active_oxygen_today_title: '[[[b| ADMITTED TODAY]]]\nOxygen prescribed\nfor admissions {stayLength}',
      hours_since_admission: 'Time since admission',
      no_patients_not_prescribed_oxygen: 'No patients not prescribed oxygen',
      usage_prediction_tooltip: 'Show active prescriptions for this location',
      hour_of_day: 'Hour of day',
      time_range: 'Time range',
      admin_date: 'Admin Date',
      admin_time: 'Admin Time',
      active_oxygen_tooltip_header: 'Prescribing numbers for <b>{ward}</b>',
      stay_length: 'Hospital stay length',
      within_length: 'Compliant if prescribed',
      admitted_datetime: 'Admission Date',
      prescribed_delay: 'Time to prescribe',
      complete_delay: 'Time to complete',
      no_restrictions: 'No restrictions',
      within: 'within',
      outside: 'outside',
      at_least: 'at least',
      at_most: 'at most',
      not_prescribed: 'Not prescribed',
      oxygen_history_tooltip_header: 'Compliance level on <b>{date}</b>',
      frequency: 'Frequency',
      from_time: 'From',
      today_tomorrow: 'Today & tomorrow ',
      definitions: 'Definitions',
      definition: 'Definition',
      prescribed_location: 'Prescribed Location',
      admitted_location: 'Admitted Location',
      discharge_location: 'Discharge Location',
      discharge_datetime: 'Discharge Date',
      monitoring_oxygen_history_patient: 'Patients not prescribed or prescribed late for {date}',
      current_location: 'Current Location',
      exempt_locations: 'Exempt Locations',
      exempt_oxygen_header: 'Exempt Locations For Oxygen Compliance',
      omitted_doses_overview_subtitle: 'Between {dateRange} for {wards}',
      update_groups: 'Update drug groups for changes',
      for: 'for',
      routes: 'routes',
      all: 'all',
      monitoring_allergy: 'Allergies',
      allergy_verifications_tooltip_header: 'Allergy Verifications for <b>{ward}</b>',
      monitoring_allergy_verifications_verified_tooltip: 'Verified',
      monitoring_allergy_verifications_verified_today_tooltip: 'Verified today',
      monitoring_allergy_verifications_verified_within_tooltip: 'Verified within {withinLengthHours}',
      monitoring_allergy_verifications_verified_today_within_tooltip: 'Verified today within {withinLengthHours}',
      monitoring_allergy_verifications_not_verified_tooltip: 'Not verified',
      monitoring_allergy_verifications_not_verified_today_tooltip: 'Not verified today',
      monitoring_allergy_verifications_not_verified_within_tooltip: 'Not verified/Verified outwith {withinLengthHours}',
      monitoring_allergy_verifications_not_verified_today_within_tooltip: 'Not verified/Verified outwith {withinLengthHours} today',
      monitoring_allergy_verifications_within_title: "[[[b| ALL INPATIENTS]]]\nAllergies verified {withinLength}\nfor admissions {stayLength}",
      monitoring_allergy_verifications_title: "[[[b| ALL INPATIENTS]]]\nAllergies verified\nfor admissions {stayLength}",
      monitoring_allergy_verifications_within_today_title: '[[[b| ADMITTED TODAY]]]\nAllergies verified {withinLength}\nfor admissions {stayLength}',
      monitoring_allergy_verifications_today_title: '[[[b| ADMITTED TODAY]]]\nAllergies verified\nfor admissions {stayLength}',
      active_vte_risk_tooltip_header: 'Stats for <b>{ward}</b>',
      monitoring_active_vte_risk_prescribed_tooltip: '{vteMeasureStatShort}',
      monitoring_active_vte_risk_prescribed_today_tooltip: '{vteMeasureStatShort} today',
      monitoring_active_vte_risk_prescribed_within_tooltip: '{vteMeasureStatShort} within {withinLengthHours}',
      monitoring_active_vte_risk_prescribed_today_within_tooltip: '{vteMeasureStatShort} today within {withinLengthHours}',
      monitoring_active_vte_risk_not_prescribed_tooltip: 'Not {vteMeasureStatShort}',
      monitoring_active_vte_risk_not_prescribed_today_tooltip: 'Not {vteMeasureStatShort} today',
      monitoring_active_vte_risk_not_prescribed_within_tooltip: 'Not {vteMeasureStatShort}/{vteMeasureStatShort} outwith {withinLengthHours}',
      monitoring_active_vte_risk_not_prescribed_today_within_tooltip: 'Not {vteMeasureStatShort}/{vteMeasureStatShort} outwith {withinLengthHours} today',
      monitoring_active_vte_risk_within_title: "[[[b| ALL INPATIENTS AGED ≥ 16]]]\n {vteMeasureStat} {withinLength}\nfor admissions {stayLength}",
      monitoring_active_vte_risk_title: "[[[b| ALL INPATIENTS AGED ≥ 16]]]\n {vteMeasureStat}\nfor admissions {stayLength}",
      monitoring_active_vte_risk_within_today_title: "[[[b| ADMITTED TODAY AGED ≥ 16]]]\n {vteMeasureStat} {withinLength}\nfor admissions {stayLength}",
      monitoring_active_vte_risk_today_title: "[[[b| ADMITTED TODAY AGED ≥ 16]]]\n {vteMeasureStat}\nfor admissions {stayLength}",
      monitoring_active_vte_risk_patient: "Active inpatients in {locationLabel} with no {vteMeasureStat} or {vteMeasureStat} after {withinLengthHours}",
      monitoring_active_vte_risk_patient_within_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours either no {vteMeasureStat} or {vteMeasureStat} after {withinLength} hours',
      monitoring_active_vte_risk_patient_within_today_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours either no {vteMeasureStat} or {vteMeasureStat} after {withinLength} hours',
      monitoring_active_vte_risk_patient_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours with no {vteMeasureStat}',
      monitoring_vte_risk_history: 'VTE Risk Assessment Compliance History',
      monitoring_vte_risk_history_name: '{vteMeasureStat} History',
      monitoring_vte_risk_history_title: '<b>{vteMeasureStat}</b> history for admissions <b>{stayLength}</b> between <b>{dateRange}</b>',
      monitoring_vte_risk_history_within_title: '<b>{vteMeasureStat}</b> history for admissions <b>{stayLength}</b> where <b>{withinLength}</b> between <b>{dateRange}</b>',
      vte_risk_history_tooltip_header: 'Inpatients admitted on <b>{date}</b> with a {vteMeasureStat}',
      monitoring_vte_risk_history_prescribed_tooltip: '{vteMeasureStatShort}',
      monitoring_vte_risk_history_prescribed_within_tooltip: '{vteMeasureStatShort} within {withinLengthHours}',
      monitoring_vte_risk_history_not_prescribed_tooltip: 'Not {vteMeasureStatShort}',
      monitoring_vte_risk_history_not_prescribed_within_tooltip: 'Not {vteMeasureStatShort}/{vteMeasureStatShort} outwith {withinLengthHours}',
      percentage_of_total_vte_risk_orders: '% of total VTE risk assessment prescriptions',
      monitoring_vte_risk_history_patient: 'Patients not {vteMeasureStatShort} or {vteMeasureStatShort} late for {date}',
      monitoring_vte_risk_history_patient_name: 'Patients not {vteMeasureStatShort} or {vteMeasureStatShort} late for {dateRange}',
      prescribed: 'Prescribed',
      not_complete: 'Not complete',
      percentage_of_total_inpatients_allergies_verified: '% of total INPATIENT Allergy Verifications',
      monitoring_allergy_verifications_history_verified_tooltip: 'Verified',
      monitoring_allergy_verifications_history_verified_within_tooltip: 'Verified within {withinLengthHours}',
      monitoring_allergy_verifications_history_not_verified_tooltip: 'Not Verified',
      monitoring_allergy_verifications_history_not_verified_within_tooltip: 'Not Verified/Verified outwith {withinLengthHours}',
      allergy_verifications_history_tooltip_header: 'Inpatients admitted on <b>{date}</b> with verified allergies',
      monitoring_allergy_verifications_history_title: '<b>Verified Allergies</b> history for admissions <b>{stayLength}</b> between <b>{dateRange}</b>',
      monitoring_allergy_verifications_history_within_title: '<b>Verified Allergies</b> history for admissions <b>{stayLength}</b> where <b>{withinLength}</b> between <b>{dateRange}</b>',
      monitoring_allergy_verifications_patient_within_title: 'Active inpatients in {locationLabel} admitted longer than {stayLength} hours either not verified or verified after {withinLength} hours',
      verified_delay: 'Time to verify',
      not_verified: 'Not verified',
      verified_location: 'Verified Location',
      monitoring_allergy_verifications_history_patient: 'Patients not verified or verified late for {date}',
      monitoring_allergy_verifications_history_patient_name: 'Patients not verified or verified late for {dateRange}',
      monitoring_allergy_verifications_history_name: 'Allergy Verification History',
      allergy_verifications: 'Allergy Verifications',
      vte_risk_assessments: 'VTE Risk Assessments',
      dots_total_dots: '% of drug DOTs',
      dots_dots: '% of drug DOTs',
      dots_bed_days: '% of bed days',
      ddds_ddds: '% of drug DDDs',
      ddds_bed_days: '% of bed days',
      usage_stats: 'Usage Stats',
      probe: 'Site Stats',
      dashboard_views: 'Reports Ran',
      last_seen: 'Last Seen',
      last_used: 'Last Used',
      days_used: 'Days in Use',
      dashboard_view_count: 'Dashboard View Count',
      dashboard_name: 'Name',
      last_24_hrs_omitted_breakdown: 'Show Last 24Hrs Med Not Available breakdown',
      oxygen_prescribed: 'Oxygen',
      allergy_status: 'Allergies',
      safety_status: 'Safety',
      vte_status: 'VTE',
      omitted_last_24hr: 'Unavailable Meds (last 24 hrs)',
      admin_stats: 'Administration Stats',
      oxygen_prescribed_details: 'Prescribed on: {event_time}. Time to prescribe: {event_delay}.',
      oxygen_not_prescribed: 'Oxygen has not been prescribed.',
      allergies_not_known: 'Allergies not known.',
      allergies_known_not_verified: 'Allergies known but not verified.',
      allergies_known_verified: 'Allergies known and verified. Time to verify: {event_delay}.',
      vte_not_complete: 'VTE NOT complete.',
      vte_complete_order_inactive: 'VTE Complete. Prescription closed or cancelled.',
      vte_complete_order_required_not_found: 'VTE Complete. Prescription required but not found.',
      vte_complete_no_order_required: 'VTE Complete. Prescription required: {reason}.',
      vte_complete_order_active: 'VTE Complete. Prophylaxis prescribed. Time to prescribe: {event_delay}.',
      show_admin_stats_admission: 'Show administration stats',
      oxygen_excluded: 'Oxygen check excluded for this location.',
      pharmacy: 'Pharmacy',
      not_prescribed_reason: 'Reason',
      assessed_date: 'Assessed',
      assessed_delay: 'Time to assess',
      patient_usage_last_days_header: "How many patients were treated with <b>{drugs}</b>?",
      patient_usage_bed_days_header: 'The average number of patients staying overnight was:',
      patient_usage_percentage_treated_header: 'What is the % of inpatients treated with <b>{drugs}</b> each day:',
      patient_usage_patients_late_header: 'How many <b>patients</b> were given an administration <b>&ge; {delay} mins</b> after it was due?',
      patient_usage_doses_late_header: 'How many <b>doses</b> were administered <b>&ge; {delay} mins</b> after they were due?',
      patient_usage_percentile_header: 'The <b>slowest {percentile_percent}%</b> of administrations take more than:',
      patient_usage_doses_late_rank_header: 'What are the <b>top 5</b> wards where you are likely to be given a dose of  <b>{drugs} &ge; {delay} mins</b> after it was due?',
      patient_usage_likelihood_general: 'Chance of late dose',
      patient_usage_likelihood_drug: 'Chance of late dose of <b>{drugs}</b>',
      stop_date: 'Stop Date',
      start_date: 'Start Date',
      percent_drug_admins: '% of total {drugs} admins',
      num_admins: 'Number of admins',
      percent_admin_loc: '% of total {drugs} admins at location',
      percent_loc_split: 'Route/Location breakdown %',
      controlled: 'Controlled Drugs',
      controlled_drugs: 'Controlled Drugs',
      current_ward: 'Current Ward',
      current_bed: 'Current Bed',
      administrator: 'Administrator',
      time: 'Time',
      substance: 'Drug',
      substance_placeholder: 'Start typing the name of the drug, e.g. Morphine',
      form_placeholder: 'Start typing the form name, e.g. Capsule',
      strength_placeholder: 'Start typing the strength, e.g. 30mg',
      insulins: 'Insulins',
      insulins_delayed_counts: 'Insulins Delayed Administration Counts',
      insulins_delayed_counts_title: 'Drugs with more than one delayed administration from <b>{wards}</b> between <b>{dateRange}</b>',
      insulins_good_news_counts: "Great news! We did not find any Insulin's medications that were delayed",
      unvalidated_count_of_overall_count: '{unvalidated} of {overall}',
      show_active_order_validations: 'Show active order validation status',
      validation_status: 'Validation Status',
      unvalidated_orders: 'Unvalidated Orders',
      verified: 'Verified',
      prescription: 'Prescription',
      prescriptions: 'Prescriptions',
      prn: 'PRN',
      dose_and_form: 'Dose and Form',
      medchart_input_form: 'Input Forms',
      medchart_intervention: 'Interventions',
      medchart_medication_reconcile_link: 'Medication Reconcile Links',
      medchart_reconciliation_event: 'Reconciliation Events',
      medchart_additive: 'Additives',
      medchart_diluent: 'Diluents',
      medchart_infusion_additive: 'Infusion Additives',
      medchart_infusion_dose: 'Infusion Doses',
      full_drug_name: 'EPMA Drug Name',
      hospital_drug_name: 'Hospital Drug Name',
      drug_brand: 'Brand',
      generic: 'Generic',
      branded: 'Branded',
      stat: 'STAT',
      active_prescriptions: 'Active Prescriptions',
      unvalidated_prescriptions: 'Unvalidated Prescriptions',
      percentage: 'Percentage',
      scheduled: 'Scheduled',
      unvalidated: 'Unvalidated',
      link_type: 'Prescription type',
      epma: 'EPMA',
      search_for: 'Search for:',
      end_date: 'End Date',
      stock_in: 'Stock In',
      stock_unit: 'Stock Unit',
      recorded_date: 'Recorded Date',
      select_patient: 'Select a patient in the filters',
      enter_hospital_num: 'Enter the hospital number of the patient',
      issue_date: 'Issue Date',
      issue_time: 'Issue Time',
      context: 'Context',
      ward_check_settings: 'Ward Check Settings',
      cost_centre: 'Cost Centre',
      top_ups_per_week: 'Top Ups per Week',
      cupboards_lockable: 'Cupboards Lockable',
      fridges: 'Fridges',
      cupboards_other: 'Cupboards Other',
      failed_checks: 'Failed checks: {count}',
      failed_check: 'Failed check',
      hour: 'Hour',
      cost_centre_code: 'Cost Centre Code',
      cost_centre_name: 'Cost Centre',
      cost_centre_display_name: 'Cost Centre Display Name',
      default_ward_check_name: 'Start typing the cost centre name',
      enter_ward_check_details: 'Enter the cost centre details below',
      add_ward_check_config: 'Add ward to config',
      ward_id_required: 'You must select a cost centre',
      ward_name_required: 'You must give the cost centre a name',
      add_cost_centre: 'Add Cost Centre',
      delete_cost_centre: 'Delete Cost Centre',
      actions: 'Actions',
      delete_ward: 'Delete Ward',
      delete_ward_check_msg: 'Delete a ward from checks configuration',
      admins_count: 'Administrations Count',
      stock_drug: 'Drug Line',
      stock_on_hand: 'Stock On Hand',
      min_or_reorder: 'Min or Re-order',
      issued_last_week: 'Issued Last Week',
      estimated_oos: 'Est. Out Of Stock',
      last_issued: 'Last Issued',
      current_orders: 'Current Orders',
      stocked: 'Stocked',
      in_use: 'In Use',
      formulary: 'Formulary',
      nsv_code: 'NSV Code',
      wholesaler_late_orders_tooltip: 'Wholesaler orders older than 2 days',
      supplier_late_orders_tooltip: 'Supplier orders older than 3 days',
      oos_7_days_tooltip: 'Stock line with no orders expected to be out of stock sometime in the next 8 to 14 days',
      stock_supply_problems_tooltip: 'Stock line with supply problems',
      stock_shortages_red_tooltip: 'Stock line issued in the last 30 days with no orders or supply problems that is either out of stock or out of stock in the next 7 days',
    }
  };

  return new VueI18n({
    locale: 'en',
    messages
  })
}