import Axios from 'axios';
import {store} from '../../store/';
import {ApiHelper} from './api_helper';

const QUERY_MAPPER = {
  
  
  
  
  
  
  usage_overview: 'administrations/usage/overview',
  
  
  
  
  
  
  
  
  
  
  usage_admin_counts: 'administrations/usage/admin_counts',
  
  
  
  
  
  
  
  
  
  
  usage_admin_counts_over_time: 'administrations/usage/admin_counts_over_time',
  
  
  
  
  
  
  
  
  
  
  usage_ward: 'administrations/usage/ward',
  
  
  
  
  
  
  
  
  
  
  usage_ward_drugs: 'administrations/usage/ward_drugs',
  
  
  
  
  
  
  
  
  
  
  usage_ward_drugs_table: 'administrations/usage/ward_drugs_table',
  
  
  
  
  
  
  
  
  
  
  usage_prediction: 'upcoming_administrations/usage/prediction',
  
  
  
  
  
  
  
  usage_prediction_breakdown_locations: 'orders/usage/prediction_breakdown_locations',
  
  
  
  
  
  
  
  
  
  
  usage_admins_list: 'administrations/usage/admins_list',
  
  
  
  
  
  
  
  
  
  
  usage_patient_admins: 'administrations/usage/patient_admins',
  
  
  
  
  
  
  
  
  
  
  usage_admins_summary: 'administrations/usage/admins_summary',
  
  
  
  
  
  
  
  
  
  
  
  
  antibiotics_active_orders: 'orders/antibiotics/active_orders',
  
  
  
  
  
  
  
  
  
  
  antibiotics_dot: 'administrations/antibiotics/dot',
  
  
  
  
  
  
  
  
  
  
  antibiotics_ddd: 'administrations/antibiotics/ddd',
  
  
  
  
  
  
  
  
  
  
  antibiotics_order_length: 'orders/antibiotics/order_length',
  
  
  
  
  
  
  antibiotics_order_length_breakdown: 'orders/antibiotics/order_length_breakdown',
  
  
  
  
  
  
  
  
  
  
  
  antibiotics_order_specialty: 'orders/antibiotics/order_specialty',
  
  
  
  
  
  
  
  
  
  
  antibiotics_admin_specialty: 'administrations/antibiotics/admin_specialty',
  
  
  
  
  
  
  
  
  
  
  antibiotics_route: 'administrations/antibiotics/route',
  
  
  
  
  
  
  
  
  
  
  antibiotics_shortage: 'stock/antibiotics/shortage',
  
  
  
  
  
  
  
  
  
  
  
  
  prescribing_active_prescriptions: 'orders/prescribing/active_prescriptions',
  
  
  
  
  
  
  
  
  
  
  prescribing_opioids: 'orders/prescribing/opioids',
  
  
  
  
  
  
  
  
  
  
  
  
  omitted_doses_league_table: 'administrations/omitted_doses/league_table',
  
  
  
  
  
  
  omitted_doses_drug_reason: 'administrations/omitted_doses/drug_reason',
  
  
  
  
  
  
  
  omitted_doses_administrator_reason: 'administrations/omitted_doses/administrator_reason',
  
  
  
  
  
  
  
  
  
  
  
  omitted_doses_early_late_doses_league_table: 'administrations/omitted_doses/early_late_doses_league_table',
  
  
  
  
  
  
  omitted_doses_drug_time_sensitivity: 'administrations/omitted_doses/drug_time_sensitivity',
  
  
  
  
  
  
  
  omitted_doses_early_late_trends: 'administrations/omitted_doses/early_late_trends',
  
  
  
  
  
  
  
  omitted_doses_hourly_time_sensitivity: 'administrations/omitted_doses/hourly_time_sensitivity',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  omitted_doses_overview_standard: 'administrations/omitted_doses/overview_standard',
  
  
  
  
  
  
  
  omitted_doses_overview_hospital: 'administrations/omitted_doses/overview_hospital',
  
  
  
  
  
  
  
  
  
  
  omitted_doses_ward_view: 'administrations/omitted_doses/ward_view',
  
  
  
  
  
  
  
  
  
  
  omitted_doses_admin_reasons: 'administrations/omitted_doses/admin_reasons',
  
  
  
  
  
  
  
  
  
  
  omitted_doses_admin_reasons_ward_compare: 'administrations/omitted_doses/admin_reasons_ward_compare',
  
  
  
  
  
  
  
  
  
  
  
  
  anticholinergics_cognition: 'administrations/anticholinergics/cognition',
  
  
  
  
  
  
  
  
  
  
  anticholinergics_overview: 'administrations/anticholinergics/overview',
  
  
  
  
  
  
  
  
  
  
  anticholinergics_breakdown: 'administrations/anticholinergics/breakdown',
  
  
  
  
  
  
  
  
  
  
  
  
  parkinsons_counts: 'administrations/parkinsons/counts',
  
  
  
  
  
  
  
  
  
  
  parkinsons_delayed_counts: 'administrations/parkinsons/delayed_counts',
  
  
  
  
  
  
  
  
  
  
  parkinsons_delayed_timeline: 'administrations/parkinsons/delayed_timeline',
  
  
  
  
  
  
  
  
  
  
  parkinsons_early_late_doses_league_table: 'administrations/parkinsons/early_late_doses_league_table',
  
  
  
  
  
  
  parkinsons_drug_time_sensitivity: 'administrations/parkinsons/drug_time_sensitivity',
  
  
  
  
  
  
  
  parkinsons_early_late_trends: 'administrations/parkinsons/early_late_trends',
  
  
  
  
  
  
  
  parkinsons_hourly_time_sensitivity: 'administrations/parkinsons/hourly_time_sensitivity',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  admin_burden_active_counts: 'administrations/admin_burden/active_counts',
  
  
  
  
  
  
  
  admin_burden_active_patients: 'administrations/admin_burden/active_patients',
  
  
  
  
  
  
  
  
  
  
  admin_burden_overview: 'administrations/admin_burden/overview',
  
  
  
  
  
  
  
  
  
  
  admin_burden_counts: 'administrations/admin_burden/counts',
  
  
  
  
  
  
  
  
  
  
  admin_burden_scores: 'administrations/admin_burden/scores',
  
  
  
  
  
  
  
  
  
  
  
  
  hospital_admissions: 'hospital_activity/hospital/admissions',
  
  
  
  
  
  
  
  
  
  
  hospital_bed_days: 'hospital_activity/hospital/bed_days',
  
  
  
  
  
  
  
  
  
  
  
  
  stock_history: 'stock/stock/history',
  
  
  
  
  
  
  
  
  
  
  stock_shortage_summary: 'stock/stock/shortage_summary',
  
  
  
  
  
  
  
  
  
  
  stock_shortages: 'stock/stock/shortages',
  
  
  
  
  
  
  
  
  
  
  stock_lookup: 'stock/stock/lookup',
  
  
  
  
  
  
  
  
  
  
  
  
  safety_active_overview: 'safety_indicator_stats/safety/active_overview',
  
  
  
  
  
  
  
  safety_location_overview: 'safety_indicator_stats/safety/location_overview',
  
  
  
  
  
  
  
  
  
  
  safety_ward_view: 'safety_indicator_stats/safety/ward_view',
  
  
  
  
  
  
  
  
  
  
  safety_covert_medications: 'administrations/safety/covert_medications',
  
  
  
  
  
  
  patient_omitted_drug_breakdown: 'administrations/patient/omitted_drug_breakdown',
  
  
  
  
  
  
  
  
  
  
  
  
  
  vte_active_patient_indicators: 'administrations/vte/active_patient_indicators',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  performance_ward: 'administrations/performance/ward',
  
  
  
  
  
  
  
  
  
  
  performance_ward_checks: 'stock/performance/ward_checks',
  
  
  
  
  
  
  performance_ward_check_breakdown: 'stock/performance/ward_check_breakdown',
  
  
  
  
  
  
  
  
  
  
  
  
  
  opioids_admins_league_table: 'administrations/opioids/admins_league_table',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_vte_risk: 'administrations/monitoring/active_vte_risk',
  
  
  
  
  
  
  
  monitoring_active_vte_risk_patient: 'administrations/monitoring/active_vte_risk_patient',
  
  
  
  
  
  
  
  monitoring_vte_risk_history: 'administrations/monitoring/vte_risk_history',
  
  
  
  
  
  
  
  monitoring_vte_risk_history_patient: 'administrations/monitoring/vte_risk_history_patient',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_allergy_verifications: 'allergy_verifications/monitoring/allergy_verifications',
  
  
  
  
  
  
  
  monitoring_allergy_verifications_patient: 'allergy_verifications/monitoring/allergy_verifications_patient',
  
  
  
  
  
  
  
  monitoring_allergy_verifications_history: 'allergy_verifications/monitoring/allergy_verifications_history',
  
  
  
  
  
  
  
  monitoring_allergy_verifications_history_patient: 'allergy_verifications/monitoring/allergy_verifications_history_patient',
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  monitoring_active_oxygen: 'orders/monitoring/active_oxygen',
  
  
  
  
  
  
  
  monitoring_active_oxygen_patient: 'orders/monitoring/active_oxygen_patient',
  
  
  
  
  
  
  
  monitoring_oxygen_history: 'orders/monitoring/oxygen_history',
  
  
  
  
  
  
  
  monitoring_oxygen_history_patient: 'orders/monitoring/oxygen_history_patient',
  
  
  
  
  
  
  
  
  
  monitoring_patient_usage_stats: 'administrations/monitoring/patient_usage_stats',
  
  
  
  
  
  
  
  
  
  
  
  
  probe_active_users: 'probe/probe/active_users',
  
  
  
  
  
  
  
  
  
  
  probe_dashboard_popularity: 'probe/probe/dashboard_popularity',
  
  
  
  
  
  
  
  
  
  
  
  
  pharmacy_monitoring_summary: 'administrations/pharmacy/monitoring_summary',
  
  
  
  
  
  
  
  
  
  
  
  patient_unvalidated_drug_breakdown: 'orders/patient/unvalidated_drug_breakdown',
  
  
  
  
  
  
  
  
  
  
  
  
  
  controlled_ward_history: 'administrations/controlled/ward_history',
  
  
  
  
  
  
  
  
  
  
  
  
  insulins_delayed_counts: 'administrations/insulins/delayed_counts',
  
  
  
  
  
  
  
  
};

export const Query = {
  query(query, dashboard, dashboard_info) {
    if (!QUERY_MAPPER[query]) {
      throw `You must define an API call for query ${query} in QUERY_MAPPER`
    }

    const params = ApiHelper.getParams(dashboard, dashboard_info);
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/${QUERY_MAPPER[query]}`, params)
  },

  hospitalQuery(tenant, data, token) {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
      }
    };

    const endpoint = 'https://{{tenant}}.tscribe.co.uk:8080/Patient';
    const port_adjusted_endpoint = tenant.toLowerCase() === 'ncic' ? endpoint.replace(':8080', ':11390') : endpoint;

    return Axios.post(port_adjusted_endpoint.replace('{{tenant}}', tenant), data, axiosConfig);
  },

  exportChart(historyId, format) {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/dashboards/export/${historyId}.${format}`)
  }
};
